import React from "react"
import PressAlbum from "../../components/PressAlbum"
import { getAlbum } from "../../data/albums"
const quintessence = getAlbum("quintessence")

export default () => (
  <PressAlbum
    album={quintessence}
    download={true}
    colors={{
      background: "white",
      text: "black",
      title: "black",
    }}
  >
    The 5th album, now available.
  </PressAlbum>
)
